import React, { FC } from 'react'
import { useTextTranslation } from '../../contexts/TextTranslation'
import Header from '../../components/header/header'
import { TRest } from '../../utils/typesFromBackend'
import { BASE_URL_CDN } from '../../utils/const'
import Mir from '../../assets/images/Mir.png'
import MasterCard from '../../assets/images/MasterCard.jpg'
import Paykeeper from '../../assets/images/paykeeper_logo.png'
import SPB from '../../assets/images/SPB.jpg'
import Visa from '../../assets/images/Visa.png'
import style from './about-us.module.css'
import { Link } from 'react-router-dom' // Import Link
import useLinkWithParams from '../../services/hooks/useLinkWithParams'
import { stringIsNotEmpty } from '../../helpers/stingIsNotEmpty'

interface TAboutUs {
  rest: TRest
  setIsScrollButtonVisible: (arg: boolean) => void
}

const AboutUs: FC<TAboutUs> = ({ rest, setIsScrollButtonVisible }) => {
  const translation = useTextTranslation()

  const createLink = useLinkWithParams()

  return (
    <div className={style.pageContainer}>
      <section className={style.section}>
        <Header
          title={translation('about-us')}
          rest={rest}
          showBottomCart={true}
          setIsScrollButtonVisible={setIsScrollButtonVisible}
        />
        <div className={style.body}>
          <div className={style['block-img']}>
            {/* eslint-disable-next-line */}
            {rest.logoPath !== '' ? (
              <img
                src={`${BASE_URL_CDN}${'/'}/${rest.logoPath}`}
                className={style['about-us__logo']}
                alt='Логотип'
              />
            ) : (
              ''
            )}
          </div>
          <div className={style.info}>
            <h1 style={{ color: `${rest.colorsSchema_id.mainTextColor}` }}>
              {rest.titleRest}
            </h1>
            <h2 style={{ color: `${rest.colorsSchema_id.mainTextColor}` }}>
              {translation('our-address')}:{' '}
              <span>
                {[
                  stringIsNotEmpty(rest.location_detail?.country)
                    ? rest.location_detail.country
                    : '',
                  stringIsNotEmpty(rest.location_detail?.city)
                    ? rest.location_detail.city
                    : '',
                  stringIsNotEmpty(rest.location_detail?.street)
                    ? rest.location_detail.street
                    : '',
                  stringIsNotEmpty(rest.location_detail?.building)
                    ? `дом ${rest.location_detail.building}`
                    : '',
                  stringIsNotEmpty(rest.location_detail?.porch)
                    ? `подъезд ${rest.location_detail.porch}`
                    : '',
                  stringIsNotEmpty(rest.location_detail?.sfloor)
                    ? `этаж ${rest.location_detail.sfloor}`
                    : ''
                ]
                  .filter((value) => value.trim().length > 0)
                  .join(', ')}
              </span>
            </h2>

            <h2 style={{ color: `${rest.colorsSchema_id.mainTextColor}` }}>
              {translation('telephone')}: <span>{rest.workPhone}</span>
            </h2>
            <h2 style={{ color: `${rest.colorsSchema_id.mainTextColor}` }}>
              {/* eslint-disable-next-line */}
              {rest.isWorkingHours ? (
                rest.textWorkingHours
              ) : (
                <>
                  {translation('we-work-with')} <span>{rest.openTime}</span>{' '}
                  {translation('before')}{' '}
                  <span
                    style={{
                      fontWeight: 'normal',
                      color: `${rest.colorsSchema_id.mainTextColor}`
                    }}
                  >
                    {rest.closeTime}
                  </span>
                </>
              )}
            </h2>
          </div>
        </div>
        <div
          style={{
            display: 'flex',
            justifyContent: 'space-around',
            paddingBottom: '30px',
            paddingTop: '50px'
          }}
        >
          <Link to={createLink('/terms-of-payment')}>
            {translation('terms-of-payment')}
          </Link>
          <Link to={createLink('/condition-for-returning-goods')}>
            {translation('condition-for-returning-goods')}
          </Link>
          <Link to={createLink('/oferta')}>{translation('offer')}</Link>
        </div>
      </section>

      <div
        style={{
          display: 'flex',
          justifyContent: 'center',
          marginBottom: '30px'
        }}
      >
        {rest.social_ids
          .filter((el) => el.title === 'vk')
          .map((item) => {
            if (item.active) {
              return (
                <a key={item._id} href={item.link}>
                  <img
                    style={{ height: '30px', width: '30px' }}
                    src={`${BASE_URL_CDN + '/' + `${item.image}`}`}
                  />
                </a>
              )
            }
            return ''
          })}
      </div>
      <div className={style.footer}>
        <div
          style={{
            margin: 'auto',
            display: 'flex',
            flexWrap: 'wrap',
            gap: '10px',
            paddingBottom: '30px'
          }}
        >
          <img className={style.logoPayment} src={Mir} />
          <img className={style.logoPayment} src={SPB} />
          <img className={style.logoPayment} src={Visa} />
          <img className={style.logoPayment} src={MasterCard} />
          <img className={style.logoPayment} src={Paykeeper} />
        </div>
      </div>
    </div>
  )
}

export default AboutUs
