import { FC, useEffect, useState } from 'react'
import {
  TCategory,
  TDish,
  TRest,
  TSubCategories
} from '../../utils/typesFromBackend'
import Dish from '../dish/dish'
import SubCategory from '../sub-category/sub-category'
import { Element } from 'react-scroll'
import style from './sub-categories.module.css'
import { useTextTranslation } from '../../contexts/TextTranslation'
import { useCurrentLanguage } from '../../contexts/CurrentLanguageContext'
import { useThrottleFn } from '../../services/hooks'

interface ISubCategories {
  subcategories: TSubCategories[] | []
  category: TCategory
  dishes: TDish[] | []
  rest: TRest
  openDishModal: (
    isNoImage: boolean,
    activeModifiers: boolean,
    dish: TDish
  ) => void
  setDishesIdForModalDel: (dishesId: string) => void
  setIsOpenDishModalDel: (value: boolean) => void
  horizontalDisplay: boolean
  setIsDishLoaded: (arg: boolean) => void
}
const SubCategories: FC<ISubCategories> = ({
  subcategories,
  category,
  dishes,
  rest,
  openDishModal,
  setDishesIdForModalDel,
  setIsOpenDishModalDel,
  horizontalDisplay,
  setIsDishLoaded
}) => {
  const [isSubCategories, setIsSubCategories] = useState(false)
  const [isDishes, setIsDishes] = useState(false)
  const translation = useTextTranslation()
  const currentLanguage = useCurrentLanguage()
  const [width, setWidth] = useState(true)
  let flag = false

  const resizeHandler = (): void => {
    if (window.innerWidth < 768 && !flag && width) {
      setWidth(false)
      flag = true
    } else if (window.innerWidth >= 768 && flag && width) {
      setWidth(true)
      flag = false
    }
  }
  const { throttled: throttledResizeHandler } = useThrottleFn(resizeHandler, {
    wait: 1000
  })

  useEffect(() => {
    window?.addEventListener('resize', throttledResizeHandler)
    return () => window?.removeEventListener('resize', throttledResizeHandler)
  }, [throttledResizeHandler])

  useEffect(() => {
    if (window.innerWidth < 768) {
      setWidth(false)
      flag = true
    } else if (window.innerWidth >= 768) {
      setWidth(true)
      flag = false
    }
  }, [])

  useEffect(() => {
    if (subcategories.length > 0) {
      setIsSubCategories(true)
    } else {
      setIsSubCategories(false)
    }
  }, [subcategories])

  useEffect(() => {
    if (dishes.length > 0) {
      setIsDishes(true)
    } else {
      setIsDishes(false)
    }
  }, [dishes])

  const styleMainTextColor = {
    color: rest.colorsSchema_id.categoryTitleColor,
    borderLeft: `3px solid ${rest.colorsSchema_id.categoryTitleColor}`
  }

  return isSubCategories ? (
    <Element name={category._id} className={style['category-block']}>
      <div
        className={style['category-block__title']}
        style={styleMainTextColor}
      >
        {category.title[currentLanguage]}
      </div>
      {isDishes ? (
        <div className={style['products-block']}>
          {category.noImagesCategory ? (
            <div className={style['products-no-image']}>
              {dishes.map((dish) => (
                <Dish
                  key={dish._id}
                  data={dish}
                  isNoImage={true}
                  rest={rest}
                  openDishModal={openDishModal}
                  setDishesIdForModalDel={setDishesIdForModalDel}
                  setIsOpenDishModalDel={setIsOpenDishModalDel}
                  horizontalDisplay={horizontalDisplay}
                  setIsDishLoaded={setIsDishLoaded}
                />
              ))}
            </div>
          ) : (
            <div
              className={
                rest.colorsSchema_id.viewGrid
                  ? !width && !horizontalDisplay
                    ? style['products-grid-2']
                    : style.products
                  : !width
                  ? style['products-grid-1']
                  : style.products
              }
            >
              {dishes.map((dish) => (
                <Dish
                  key={dish._id}
                  data={dish}
                  isNoImage={false}
                  rest={rest}
                  openDishModal={openDishModal}
                  setDishesIdForModalDel={setDishesIdForModalDel}
                  setIsOpenDishModalDel={setIsOpenDishModalDel}
                  horizontalDisplay={horizontalDisplay}
                  setIsDishLoaded={setIsDishLoaded}
                />
              ))}
            </div>
          )}
        </div>
      ) : (
        ''
      )}
      {subcategories.map((subcategory) => (
        <Element
          name={subcategory.category._id}
          className={style['category-block']}
          key={subcategory.category._id}
        >
          <SubCategory
            category={subcategory.category}
            dishes={subcategory.dishes}
            subcategories={subcategory.subcategories}
            rest={rest}
            openDishModal={openDishModal}
            horizontalDisplay={horizontalDisplay}
            setIsDishLoaded={setIsDishLoaded}
          />
        </Element>
      ))}
    </Element>
  ) : (
    <Element name={category._id} className={style['category-block']}>
      <div
        className={style['category-block__title']}
        style={styleMainTextColor}
      >
        {category.title[currentLanguage]}
      </div>
      <div className={style['products-block']}>
        {category.noImagesCategory ? (
          isDishes ? (
            <div className={style['products-no-image']}>
              {dishes.map((dish) => (
                <Dish
                  key={dish._id}
                  data={dish}
                  isNoImage={true}
                  rest={rest}
                  openDishModal={openDishModal}
                  setDishesIdForModalDel={setDishesIdForModalDel}
                  setIsOpenDishModalDel={setIsOpenDishModalDel}
                  horizontalDisplay={horizontalDisplay}
                  setIsDishLoaded={setIsDishLoaded}
                />
              ))}
            </div>
          ) : (
            ''
          )
        ) : isDishes ? (
          <div
            className={
              rest.colorsSchema_id.viewGrid
                ? !width && !horizontalDisplay
                  ? style['products-grid-2']
                  : style.products
                : !width
                ? style['products-grid-1']
                : style.products
            }
          >
            {dishes.map((dish) => (
              <Dish
                key={dish._id}
                data={dish}
                isNoImage={false}
                rest={rest}
                openDishModal={openDishModal}
                setDishesIdForModalDel={setDishesIdForModalDel}
                setIsOpenDishModalDel={setIsOpenDishModalDel}
                horizontalDisplay={horizontalDisplay}
                setIsDishLoaded={setIsDishLoaded}
              />
            ))}
          </div>
        ) : (
          ''
        )}
      </div>
      {isDishes ? (
        ''
      ) : (
        <div className={style.noproducts}>
          <div className={`${style.alert} ${style['alert-danger']}`}>
            {translation('no-elements')}
          </div>
        </div>
      )}
    </Element>
  )
}

export default SubCategories
