import { FC, useEffect, useState } from 'react'
import { Link } from 'react-scroll'
import style from './navbar.module.css'
import { TRest } from '../../utils/typesFromBackend'
import { ILink } from '../../utils/types'
import 'owl.carousel/dist/assets/owl.carousel.css'
import 'owl.carousel/dist/assets/owl.theme.default.css'
import { useInView } from 'react-intersection-observer'
import { Swiper, SwiperSlide } from 'swiper/react'
/* import 'swiper/swiper-bundle.min.css' */
import 'slick-carousel/slick/slick.css'
import 'slick-carousel/slick/slick-theme.css'
import { Swiper as SwiperClass } from 'swiper/types'
/* import SwiperCore, { FreeMode } from 'swiper/core' */
interface INavbar {
  data: ILink[]
  rest: TRest
}

const Navbar: FC<INavbar> = ({ data, rest }) => {
  const [numberSlides, setNumberSlides] = useState(0)
  const [activeIndex, setActiveIndex] = useState<number>(0)
  const [open, setOpen] = useState<boolean>(false)
  const [windowInnerWidth, setWindowInnerWidth] = useState<number>(
    -window.innerWidth
  )
  const [leftValue, setLeftValue] = useState<number>(-window.innerWidth)
  const [display, setDispaly] = useState<string>('none')
  const [swiperInstance, setSwiperInstance] = useState<SwiperClass | null>(null)

  const backgroundCarousel = {
    background: rest.colorsSchema_id.backgroundCarousel,
    borderTop: `1px solid ${rest.colorsSchema_id.borderCarousel}`,
    borderBottom: `1px solid ${rest.colorsSchema_id.borderCarousel}`
  }

  const [ref, inView, enter] = useInView({
    threshold: 0.22
  })

  useEffect(() => {
    if (rest.tariff === 'Basic') {
      if (enter != null) {
        const groupSquare = document.getElementById(
          'groupSquare'
        ) as HTMLElement
        const sliderDiv = document.getElementById('sliderDiv') as HTMLElement
        const subcategoriesContainer = document.getElementById(
          'subcategories-container'
        )
        if (
          sliderDiv != null &&
          subcategoriesContainer != null &&
          !inView &&
          groupSquare !== null
        ) {
          sliderDiv.style.position = 'fixed'
          sliderDiv.style.right = '0px'
          sliderDiv.style.left = '0px'
          subcategoriesContainer.style.marginTop = '60px'
          groupSquare.style.position = 'fixed'
          groupSquare.style.right = '0px'
          groupSquare.style.left = '0px'
        }
      }
    }
  }, [inView])

  useEffect(() => {
    setNumberSlides(data.length)
  }, [data])

  // Функция для перемещения к указанному слайду при клике
  const handleClick = (index: number): void => {
    setActiveIndex(index)
  }

  useEffect(() => {
    const body = document.querySelector('body')
    if (body != null) {
      body.style.overflow = open ? 'hidden' : 'auto'
    }
  }, [open])

  useEffect(() => {
    if (open) {
      setTimeout(() => setWindowInnerWidth(0), 0)
      setTimeout(() => setLeftValue(window.innerWidth - 60), 0)
      setDispaly('block')
    } else {
      setWindowInnerWidth(-window.innerWidth)
      setLeftValue(-window.innerWidth)
      setTimeout(() => setDispaly('none'), 300)
    }
  }, [open])

  // eslint-disable-next-line multiline-ternary
  return numberSlides > 0 ? (
    <>
      {/* выезжающее слева меню */}
      <div
        style={{
          background: `${rest.colorsSchema_id.leftMenuBgColor}`,
          display: `${display}`,
          left: `${windowInnerWidth}` + 'px',
          overflowX: 'hidden'
        }}
        className={style.popup}
      >
        {/* крестик закрытия левого меню */}
        <div
          className={style.popupOpen}
          style={{
            position: 'fixed',
            left: `${leftValue}` + 'px',
            top: '10px',
            display: `${display}`
          }}
          onClick={() => setOpen(false)}
        >
          {/* SVG крестика ? */}
          <svg
            style={{ cursor: 'pointer' }}
            width='33'
            height='34'
            viewBox='0 0 33 34'
            xmlns='http://www.w3.org/2000/svg'
          >
            <path
              d='M16.4999 31.1668C10.9361 31.17 5.91861 27.7184 3.78897 22.4225C1.65933 17.1266 2.8373 11.0303 6.77318 6.97851C10.2482 3.39818 15.3132 1.99989 20.0602 3.31039C24.8071 4.62088 28.515 8.44106 29.7869 13.3319C31.0589 18.2227 29.7017 23.4412 26.2267 27.0215C23.6519 29.6863 20.1494 31.1789 16.4999 31.1668ZM5.49993 17.2437C5.56499 23.4788 10.5064 28.4885 16.5584 28.4552C22.6104 28.4215 27.4993 23.3573 27.4993 17.1218C27.4993 10.8864 22.6104 5.82219 16.5584 5.78851C10.5064 5.75516 5.56499 10.7649 5.49993 17V17.2437ZM12.9373 22.6667L10.9999 20.6692L14.5612 17L10.9999 13.3308L12.9387 11.3333L16.4999 15.0025L20.0612 11.3333L21.9999 13.3308L18.4387 17L21.9999 20.6692L20.0626 22.6667L16.4999 18.9975L12.9387 22.6667H12.9373Z'
              fill={rest.colorsSchema_id.mainColor}
            />
          </svg>
        </div>
        <div
          style={{
            display: 'flex',
            flexDirection: 'column'
          }}
        >
          {/* массив ссылок левого слайдера категорий */}
          {data
            .filter((el) => el.title !== '')
            .map((link, index) => (
              <>
                <div className={style.sliderItemParent} key={link._id}>
                  <Link
                    style={{
                      color: `${rest.colorsSchema_id.mainTextColor}`,
                      whiteSpace: 'normal'
                    }}
                    className={`${style['categories-parent']} ${style['categories-popup__category']}`}
                    onSetActive={() => {
                      setActiveIndex(index)
                      setOpen(false)
                    }}
                    to={link._id}
                    spy={true}
                    smooth={true}
                    offset={-150}
                    duration={500}
                    onClick={() => {
                      handleClick(index)
                    }}
                  >
                    {link.title}
                  </Link>
                </div>
                <hr
                  style={{
                    border: `1px solid ${rest.colorsSchema_id.stroke}`,
                    width: '99%',
                    opacity: '0.2'
                  }}
                />
              </>
            ))}
        </div>
      </div>

      {/* горизонтальная полоса меню */}
      <div id='sliderDiv' className={style.sliderDiv}>
        <div
          style={backgroundCarousel}
          id='groupSquare'
          className={style.groupSquare}
          onClick={() => setOpen(true)}
        >
          {/* девять квадратов */}
          <div style={{ position: 'relative', top: '18px', left: '10px' }}>
            <div style={{ display: 'flex' }}>
              <div
                style={{ background: `${rest.colorsSchema_id.mainColor}` }}
                className={style.square}
              ></div>
              <div
                style={{ background: `${rest.colorsSchema_id.mainColor}` }}
                className={style.square}
              ></div>
              <div
                style={{ background: `${rest.colorsSchema_id.mainColor}` }}
                className={style.square}
              ></div>
            </div>
            <div style={{ display: 'flex', marginTop: '3px' }}>
              <div
                style={{ background: `${rest.colorsSchema_id.mainColor}` }}
                className={style.square}
              ></div>
              <div
                style={{ background: `${rest.colorsSchema_id.mainColor}` }}
                className={style.square}
              ></div>
              <div
                style={{ background: `${rest.colorsSchema_id.mainColor}` }}
                className={style.square}
              ></div>
            </div>
            <div style={{ display: 'flex', marginTop: '3px' }}>
              <div
                style={{ background: `${rest.colorsSchema_id.mainColor}` }}
                className={style.square}
              ></div>
              <div
                style={{ background: `${rest.colorsSchema_id.mainColor}` }}
                className={style.square}
              ></div>
              <div
                style={{ background: `${rest.colorsSchema_id.mainColor}` }}
                className={style.square}
              ></div>
            </div>
          </div>
        </div>
        <div
          className={style.navbarContainer}
          id={'navbar-container'}
          ref={ref}
          style={backgroundCarousel}
        >
          <div className={style.sliderContainer} ref={ref}>
            <Swiper
              onSwiper={setSwiperInstance}
              slidesPerView='auto'
              initialSlide={activeIndex}
              touchRatio={2}
              freeMode={{ momentum: true }}
              enabled={true}
            >
              {/* кнопки горизонтального меню */}
              {data.map((link, index) => (
                <SwiperSlide key={link._id}>
                  <div
                    className={`${style.sliderItemParent} ${style.sliderItem}`}
                  >
                    <Link
                      to={link._id}
                      spy={true}
                      smooth={true}
                      offset={-150}
                      duration={500}
                      onClick={() => handleClick(index)}
                      className={`${style['categories-parent']} ${style['categories-main__category']}`}
                      onSetActive={() => {
                        // eslint-disable-next-line
                        if (swiperInstance) {
                          swiperInstance.slideTo(index)
                        }
                      }}
                      style={{
                        color:
                          index === activeIndex
                            ? rest.colorsSchema_id.buttonActiveTextColor
                            : rest.colorsSchema_id.textColorButtonCorousel,
                        background:
                          index === activeIndex
                            ? rest.colorsSchema_id.buttonActiveCarousel
                            : rest.colorsSchema_id.backgroundButtonCarousel,
                        visibility: link.title === '' ? 'hidden' : 'visible'
                      }}
                    >
                      {link.title}
                    </Link>
                  </div>
                </SwiperSlide>
              ))}
            </Swiper>
          </div>
        </div>
      </div>
    </>
  ) : null
}

export default Navbar
