import { useLocation } from 'react-router-dom'

// Определение типа функции для создания ссылки
type CreateLink = (
  baseUrl: string,
  params?: Record<string, string | null>
) => string

const useLinkWithParams = (): CreateLink => {
  const location = useLocation()
  const search = location.search
  const urlParams = new URLSearchParams(search)

  const numberTable = urlParams.get('numberTable')
  const restID = urlParams.get('rest_id')

  const createLink: CreateLink = (baseUrl: string, additionalParams = {}) => {
    let url = baseUrl
    const params: string[] = []

    if (numberTable !== null) {
      params.push(`numberTable=${encodeURIComponent(numberTable)}`)
    }
    if (restID !== null) {
      params.push(`rest_id=${encodeURIComponent(restID)}`)
    }

    // дополнительные параметры
    for (const [key, value] of Object.entries(additionalParams)) {
      if (value !== null && value !== undefined) {
        params.push(`${encodeURIComponent(key)}=${encodeURIComponent(value)}`)
      }
    }

    if (params.length > 0) {
      url += `/?${params.join('&')}`
    }
    return url
  }

  return createLink
}

export default useLinkWithParams
