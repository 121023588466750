import React, { useRef, FC } from 'react'

import { useAnimate } from '../../services/hooks/animate'
import { useOnClickOutside } from '../../services/hooks/on-click-outside'
import { TRest, TSale } from '../../utils/typesFromBackend'
import ButtonPopupClose from '../ui/button-popup-close/button-popup-close'
import style from './sale-modal.module.css'
import { BASE_URL_CDN } from '../../utils/const'

interface ISaleModal {
  sale: TSale
  data: TRest
  closeModal: () => void
}

const SaleModal: FC<ISaleModal> = ({ sale, data, closeModal }) => {
  const params = {
    mount: {
      style: {
        opacity: 1,
        background: `${data.colorsSchema_id.backgroundColor}`
      },
      delay: 500
    },
    unMount: {
      style: {
        opacity: 0,
        background: 'rgb(5 32 46 / 90%)'
      },
      delay: 1000
    }
  }
  const { styleAnimate, handleClose } = useAnimate(params)

  React.useEffect(() => {
    document.addEventListener('keydown', closeESC)
    return () => document.removeEventListener('keydown', closeESC)
  })

  const EscCode = 27

  function closeESC (evt: KeyboardEvent): void {
    if (evt.keyCode === EscCode) {
      handleClose(closeModal)
    }
  }

  const node = useRef<HTMLDivElement>(null)
  useOnClickOutside(node, handleClick)

  const styleMainTextColor = {
    color: `${data.colorsSchema_id.colorTextSale}`
  }
  const fillButtonClose = data.colorsSchema_id.buttonTextColor
  const backgroundColorButton = data.colorsSchema_id.buttonColor

  function handleClick (): void {
    handleClose(closeModal)
  }
  return (
    <div className={style.popup} style={styleAnimate}>
      <div className={style.popup__container} ref={node}>
        <img
          className={style.popup__image}
          src={`${BASE_URL_CDN}${'/'}${sale.image}`}
          alt={sale.title}
        />
        <p
          className={style['category-block__title']}
          style={styleMainTextColor}
        >
          {sale.description}
        </p>
        <div
          className={style.popup__close}
          style={{ backgroundColor: `${backgroundColorButton}` }}
          onClick={handleClick}
        >
          <ButtonPopupClose fill={fillButtonClose} />
        </div>
      </div>
    </div>
  )
}
export default SaleModal
