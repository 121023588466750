/* eslint-disable multiline-ternary */
/* eslint-disable @typescript-eslint/indent */
/* eslint-disable @typescript-eslint/no-unnecessary-type-assertion */
/* eslint-disable @typescript-eslint/strict-boolean-expressions */
import React, { FC, useEffect, useState } from 'react'
import Sales from '../../components/sales/sales'
import SubCategories from '../../components/sub-categories/sub-categories'
import style from './main.module.css'
import Navbar from '../../components/navbar/navbar'
import {
  EDayOfWeek,
  TDish,
  TRest,
  TSale,
  TSubCategories
} from '../../utils/typesFromBackend'
import { ILink, RootState } from '../../utils/types'
import Header from '../../components/header/header'
import IsAdult from '../../components/adult/modal-isAdult'
import { DishRecommendations } from '../../components/dish-clue/dish-recommendations'
import { RecomendationMenu } from '../../components/dish-clue/recomendation-menu'
import DishModalDel from '../../components/dish-modal-del/dish-modal-del'
import { useCurrentLanguage } from '../../contexts/CurrentLanguageContext'
import { CustomSpinner } from '../../components/spinners/customSpinner/customSpinner'
import { useDispatch, useSelector } from 'react-redux'
import CategoriesCardView from '../../components/categories-card-view/categories-card-view'
// import { useLocation } from 'react-router'
import { scroller } from 'react-scroll'
import leftArrow from '../../assets/images/left_arrow.png'
import { showCategoriesCardView } from '../../services/actions'
import { toDateFromHoursAndMinutes } from '../../helpers/date'

interface IMain {
  rest: TRest
  sales: TSale[] | []
  openSaleModal: (sale: TSale) => void
  menu: TSubCategories[]
  openDishModal: (
    isNoImage: boolean,
    activeModifiers: boolean,
    dish: TDish
  ) => void
  isAdult: boolean
  setIsAdult: Function
  language: string
  setIsDishLoaded: (arg: boolean) => void
  isDishLoaded: boolean
  setIsScrollButtonVisible: (arg: boolean) => void
}

const Main: FC<IMain> = ({
  rest,
  sales,
  openSaleModal,
  menu,
  openDishModal,
  language,
  setIsDishLoaded,
  isDishLoaded,
  setIsScrollButtonVisible
}) => {
  const currentLanguage = useCurrentLanguage()
  const [showSales, setShowSales] = React.useState(false)
  const [filteredMenu, setFilteredMenu] = React.useState<TSubCategories[]>([])
  const [showRecomendations, setShowRecomendations] = React.useState(false)
  const [recommendationDishes, setRecommendationDishes] = useState<TDish[]>([])
  const [showBottomCart, setShowBottomCart] = React.useState(false)
  // ссылки на категории для навбара
  const [arrayCategoriesInNavBar, setArrayCategoriesInNavBar] = React.useState<
    ILink[]
  >([])
  const isAdultСonfirmed = localStorage.getItem('adult')
  const [isOpenDishModalDel, setIsOpenDishModalDel] = React.useState(false)
  const [dishesIdForModalDel, setDishesIdForModalDel] =
    React.useState<string>('')
  const [isAdult, setIsAdult] = React.useState(true)
  const [categoryURLHash, setCategoryURLHash] = React.useState('')
  // const location = useLocation()
  // eslint-disable-next-line
  const [horizontalDisplay, setHorizontalDisplay] = React.useState(false) // Параметр для отображения блюд в горизонтальном виде

  const isCategoriesCardView = useSelector(
    (state: RootState) => state.showCategoriesCards.show
  )

  const dispatch = useDispatch()

  const styleCustomColor = {
    backgroundColor: rest?.colorsSchema_id?.mainTextColor
  }

  React.useEffect(() => {
    if (rest.tariff === 'Basic') {
      setShowBottomCart(false)
      setShowSales(false)
    } else {
      setShowBottomCart(true)
      setShowSales(true)
    }
  }, [rest.tariff])

  React.useEffect(() => {
    const resultArray: ILink[] = []
    let resultMenuArray: TSubCategories[] = []
    const mappedDays = Object.values(EDayOfWeek)
    const currentDay = new Date().getDay()
    const currentTime = new Date().getTime()

    const processDisplayCategory = (item: TSubCategories): boolean => {
      let isDisplayItem = true
      if (item.category.isDisplayIntervalCategory) {
        if (item.category.displayInterval.length) {
          const intervals = item.category.displayInterval
          const days = intervals.map((interval) => interval?.day)
          let indexCurrentDay = currentDay - 1
          if (indexCurrentDay === -1) {
            indexCurrentDay = 6
          }
          let flagIsCheckDay = false
          days.forEach((dayOfWeek, indexDayOfWeek) => {
            if (dayOfWeek === mappedDays[indexCurrentDay]) {
              flagIsCheckDay = true
              const times = intervals[indexDayOfWeek]?.times ?? []

              if (times.length) {
                times.forEach((time) => {
                  let dateStart, dateEnd
                  if (time?.start) {
                    dateStart = toDateFromHoursAndMinutes(time?.start)
                  }
                  if (time?.end) {
                    dateEnd = toDateFromHoursAndMinutes(time?.end)
                  }
                  if (dateStart && dateEnd) {
                    if (
                      dateStart.getTime() < currentTime &&
                      currentTime < dateEnd.getTime()
                    ) {
                      isDisplayItem = true
                    } else {
                      isDisplayItem = false
                    }
                  }
                })
              }
            }
          })
          if (!flagIsCheckDay) {
            isDisplayItem = false
          }
        }
      }
      return isDisplayItem
    }

    function removeCategoryById(subCategories: TSubCategories[], idToRemove: string): TSubCategories[] {
      return subCategories.reduce((acc: TSubCategories[], subCategory) => {
        if (subCategory.category._id === idToRemove) {
          return acc // не добавляем в результат
        }

        // Если в subcategories есть вложенные подкатегории, рекурсивно проверяем их
        const updatedSubCategories = removeCategoryById(subCategory.subcategories, idToRemove)

        // Добавляем текущую подкатегорию в массив, обновляем подкатегории
        acc.push({ ...subCategory, subcategories: updatedSubCategories })
        return acc
      }, [])
    }
    const processSubcategories = (subcategories: any): void => {
      subcategories.forEach((subcategory: any) => {
        const isDisplayItem = processDisplayCategory(subcategory)
        if (isDisplayItem) {
          resultArray.push({
            _id: subcategory.category._id,
            title: subcategory.category.title[currentLanguage] as string,
            url: subcategories[0].category.image
          })
          if (
            subcategory.subcategories &&
            subcategory.subcategories.length > 0
          ) {
            processSubcategories(subcategory.subcategories)
          }
        } else {
          const idToRemove = subcategory.category._id
          const updatedSubCategories = removeCategoryById(resultMenuArray, idToRemove)
          resultMenuArray = updatedSubCategories
        }
      })
    }

    menu.forEach((item) => {
      const isDisplayItem = processDisplayCategory(item)
      if (isDisplayItem) {
        resultMenuArray.push(item)
        resultArray.push({
          _id: item.category._id,
          title: item.category.title[currentLanguage] as string
        })
        if (item.subcategories && item.subcategories.length > 0) {
          processSubcategories(item.subcategories)
        }
      }
    })
    setArrayCategoriesInNavBar(resultArray)
    setFilteredMenu(resultMenuArray)
  }, [menu, currentLanguage])

  const styleBackgroundColor = {
    background: rest.colorsSchema_id.backgroundColor
  }

  const handleClick = (): void => {
    dispatch(showCategoriesCardView(true))
    if (window.location.hash) {
      // Получаем текущий URL без хэша
      const urlWithoutHash = window.location.href.split('#')[0]
      // Меняем текущую запись истории, убирая хэш
      window.history.replaceState(null, document.title, urlWithoutHash)
    }
    setCategoryURLHash('')
  }

  useEffect(() => {
    setCategoryURLHash(window.location.hash.replace('#', ''))
    if (categoryURLHash) {
      setTimeout(() => {
        scroller.scrollTo(categoryURLHash, {
          duration: 500,
          smooth: 'easeInOutQuart',
          offset: -100
        })
      }, 0)
    }
  }, [isCategoriesCardView, arrayCategoriesInNavBar, categoryURLHash])

  return (
    <div className={style.page}>
      <DishRecommendations
        recommendationDishes={recommendationDishes}
        rest={rest}
        openDishModal={openDishModal}
        setShowRecomendations={setShowRecomendations}
        setRecommendationDishes={setRecommendationDishes}
        language={language}
        horizontalDisplay={horizontalDisplay}
      />
      <div className={style.header} style={styleBackgroundColor}>
        <Header
          title={rest.titleRest}
          rest={rest}
          showBottomCart={showBottomCart}
          setIsScrollButtonVisible={setIsScrollButtonVisible}
        />
      </div>

      <div className={style.content}>
        {/* рекламный баннер */}
        {showSales && <Sales sales={sales} openSaleModal={openSaleModal} />}

        {isCategoriesCardView ? (
          // категории плитками
          <div className={style.categoriesCardViewWrapper}>
            <CategoriesCardView
              rest={rest}
              arrayCategoriesInNavBar={arrayCategoriesInNavBar}
            />
          </div>
        ) : (
          <>
            {!isDishLoaded && <CustomSpinner rest={rest} />}
            {/* иначе - обычное меню */}
            <Navbar data={arrayCategoriesInNavBar} rest={rest} />
            {showRecomendations && (
              <RecomendationMenu
                recommendationDishes={recommendationDishes}
                rest={rest}
                openDishModal={openDishModal}
                language={language}
                horizontalDisplay={horizontalDisplay}
              />
            )}
            {/* блок с блюдами */}
            <div id='subcategories-container'>
              {filteredMenu.map((item) => (
                <SubCategories
                  key={item.category._id}
                  subcategories={item.subcategories}
                  dishes={item.dishes}
                  category={item.category}
                  rest={rest}
                  openDishModal={openDishModal}
                  setIsOpenDishModalDel={setIsOpenDishModalDel}
                  setDishesIdForModalDel={setDishesIdForModalDel}
                  horizontalDisplay={horizontalDisplay}
                  setIsDishLoaded={setIsDishLoaded}
                />
              ))}
            </div>
          </>
        )}
      </div>
      {categoryURLHash && (
        <button
          className={style.buttonLeft}
          style={styleCustomColor}
          onClick={handleClick}
        >
          <img src={leftArrow} alt='Кнопка Назад' />
        </button>
      )}

      {!isAdultСonfirmed && rest.isAdult && isAdult && (
        <IsAdult rest={rest} setIsAdult={setIsAdult} />
      )}
      {isOpenDishModalDel && (
        <DishModalDel
          rest={rest}
          dishesId={dishesIdForModalDel}
          setIsOpenDishModalDel={setIsOpenDishModalDel}
        />
      )}
    </div>
  )
}

export default Main
