import { FC, useEffect, useState } from 'react'
import style from './category-card.module.css'
import { useHistory } from 'react-router-dom'
import { useDispatch } from 'react-redux'
import { showCategoriesCardView } from '../../services/actions'
import { BASE_URL_CDN } from '../../utils/const'
import { TRest } from '../../utils/typesFromBackend'
import imageNoPhoto from '../../assets/images/no_photo.png'

interface ICardProps {
  cardImage: string | undefined
  cardTitle: string
  cardID: string
  cardTitleStyle?: React.CSSProperties
  rest: TRest
}

const CategoryCard: FC<ICardProps> = ({
  cardImage,
  cardTitle,
  cardID,
  cardTitleStyle,
  rest
}) => {
  const [img, setImg] = useState('')
  const history = useHistory()

  const dispatch = useDispatch()

  const handleClick = (id: string): void => {
    dispatch(showCategoriesCardView(false))
    history.push(`/#${id}`)
  }

  // добавление фото категории
  useEffect(() => {
    if (cardImage != null) {
      setImg(BASE_URL_CDN + '/' + `${cardImage}`)
    } else {
      if (rest.logoPath.length > 0) {
        // если нет фотки - берем лого ресторана
        setImg(BASE_URL_CDN + '/' + `${rest.logoPath}`)
      } else {
        setImg(imageNoPhoto)
      }
    }
  }, [cardImage, rest.logoPath])

  return (
    <div className={style.card} onClick={() => handleClick(cardID)}>
      <img className={style.image} src={img} alt='Фото категории' />
      <div className={style.title} style={cardTitleStyle}>
        {cardTitle?.length > 27 ? `${cardTitle.slice(0, 27)}...` : cardTitle}
      </div>{' '}
    </div>
  )
}

export default CategoryCard
